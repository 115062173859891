@import './vars.scss';
@import '../css/reset.css';

body{
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  background: #fff;
  font-weight: 400;
  color: #000;

  @media (max-width: $xlg) {
    font-size: 14px;
  }
  @media (max-width: $lg) {
    font-size: 14px;
  }
  @media (max-width: $md) {
    padding-top: 3em;
  }
  @media (max-width: $sm) {
    font-size: 13.5px;
  }
}

.container{
  width: 100%;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
  max-width: $xlg;

  @media (max-width: $xlg) {
    max-width: $lg;
  }
  @media (max-width: $lg) {
    max-width: $md;
  }
  @media (max-width: $md) {
    max-width: $sm;
  }
  @media (max-width: $sm) {
    max-width: 540px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.button{
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .2s ease;
  background: transparent;
  border: 2px solid #fff;
  border-radius: 20px;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #fff;
  line-height: 1;
  text-decoration: none;

  &:hover{
    background: #fff;
    color: #000;
  }
}

.header{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background: transparent;
  z-index: 100;
  padding: 3em 0 2em;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.67) 0%, rgba(0, 0, 0, 0) 72.4%);

  
  @media (max-width: $md) {
    position: fixed;
    left: 0;
    top: 0;
    background: url(~/images/header__bg.jpg) no-repeat center center;
    background-size: cover;
    width: 100%;
    transition: all .2s ease;
    z-index: 1000;
    display: flex;
    flex-direction: column;

    &-active{
      & .header__list{
        transform: translateY(0);
        opacity: 1;
      }

      & .header__burger_line-1{
        transform: scaleX(.5);
      }
    }
  }
  
  @media (max-width: $sm) {
    padding: 1em 0;
  }
  
  &__row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &__col{
    &-rightside{
      @media (max-width: $md) {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }

  &__nav{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  &__list{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $md) {
      position: absolute;
      top: 100%;
      left: 0;
      height: 100vh;
      width: 100%;
      transform: translateY(-130%);
      opacity: 0;
      background: rgba(7, 26, 36, .8);
      backdrop-filter: blur(4px);
      justify-content: center;
      flex-direction: column;
      transition: .2s all ease;
    }
  }

  &__item{
    margin-right: 2.4em;

    @media (max-width: $lg) {
      margin-right: 1em;
    }
    @media (max-width: $md) {
      position: relative;
      padding-top: 3px;
      margin-right: 0;
      width: 100%;
      text-align: center;

      &::before{
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        height: 1px;
        width: 30px;
        border-radius: 1px;
        background: #fff;
      }
    }
  }

  &__link{
    display: inline-block;
    position: relative;
    color: #fff;
    text-decoration: none;
    font-weight: 300;
    font-size: 1.33em;
    padding: 1.15em 0;
    line-height: 1;

    @media (max-width: $lg) {
      font-size: 1.1em;
    }

    @media (max-width: $md) {
      width: 100%;
      font-size: 19px;
    }
  }

  &__logo{
    width: 17em;

    @media (max-width: $lg) {
      width: 15em;
    }
  }

  @media (max-width: $md) {    
    &__burger{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      margin-left: 1.5em;
      width: 3em;
      height: 1.66em;
      cursor: pointer;
  
      &_line{
        display: block;
        height: 2px;
        width: 100%;
        background: #fff;
        transition: .2s transform ease;

        &-1{
          transform-origin: right;
        }
      }
    }
  }

  &__button{
    display: flex;
    padding: .6em 1.2em;
    font-size: 1.33em;


    @media (max-width: $lg) {
      padding: .6em;
      font-size: 1.1em;
    }

    @media (max-width: $md) {
      display: flex;
      font-size: 17px;
      width: 240px;
      height: 50px;
    }

    @media (max-width: $sm) {
      width: auto;
      padding: .5em 1em;
      font-size: 14px;
    }
    @media (max-width: 360px) {
      display: none;
    }
  }
  &__button-min{
    font-weight: 500;

    @media (min-width: 360px) {
      display: none;
    }
  }
}

.wrapper{
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
}

.breeze-1{
  position: relative;

  &::before{
    content: '';
    position: absolute;
    z-index: 2;
    left: 0;
    bottom: 0;
    height: 10%;
    width: 100%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #f2eee5 90%);
  }

  &__media{
    position: relative;
    height: 95vh;
    min-height: 40em;
    max-height: 55em;
    overflow: hidden;

    @media (max-width: $sm) {
      height: 60vh;
      min-height: 0;
      max-height: 60vh;
    }
  }

  &__video{
    position: absolute;
    z-index: 1;
    background: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    min-height: 100%;
    min-width: 100%;
    object-fit: cover;
  }

  &__footer{
    position: absolute;
    z-index: 5;
    left: 0;
    bottom: 0;
    transform: translateY(40%);
    width: 100%;
  }
  &__bottle{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 30%;
    transition: .5s all ease .1s;

    @media (max-width: $md) {
      width: 40%;
    }
    @media (max-width: $sm) {
      width: 70%;
    }

    &-hide{
      opacity: 0;
      transform: translate(-50%, -20%);
    }
  }
  &__title{
    position: absolute;
    z-index: 5;
    top: 39%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 56%;

    @media (max-width: $md) {
      width: 66%;
    }
    @media (max-width: $sm) {
      width: 90%;
    }
  }
  &__cherry{
    width: 100%;
  }
}

.breeze-2{
  background: #f2eee5;
  padding: 16em 0 6.5em;

  @media (max-width: $md) {
    padding: 10em 0 6em;
  }

  @media (max-width: $sm) {
    padding: 8em 0 4em;
  }

  &__items{
    position: relative;
    
    @media (min-width: $sm) {
      & .swiper-wrapper{
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
      }
      & .swiper-button-next,
      & .swiper-button-prev{
        display: none;
      }
    }
    
    @media (max-width: $sm) {
      overflow: hidden;
      padding-top: 3.5em;
    }
  }

  &__item{
    width: 22%;
    margin-bottom: 5em;

    &_head{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 4em;
    }

    &_text{
      text-align: center;
      margin-top: .5em;
      font-weight: 300;
      font-size: 1.33em;
      line-height: 1.375;
    }
  }
}

.breeze-3{
  
  &__items{
    display: flex;
    flex-direction: row;

    @media (max-width: $md) {
      flex-direction: column;
    }
  }

  &__item{
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 25%;
    padding-bottom: 50px;
    min-height: 75vh;
    max-height: 40em;
    transition: .2s all ease;

    @media (max-width: $md) {
      width: 100%;
      padding-top: 50px;
      min-height: auto;
      max-height: 30vh;
    }

    &-active{
      padding-left: 5%;
      padding-right: 5%;
      width: 50%;
      
      @media (max-width: $md) {
        width: 100%;
        padding-left: 30px;
        padding-right: 30px;
        max-height: 100%;
      }

      & .breeze-3__item_title{
        transform: translateY(0);
        opacity: 1;
      }
      & .breeze-3__item_list{
        opacity: 1;
      }

      & .breeze-3__item_subtitle{
        position: relative;
        bottom: inherit;
        left: inherit;
      }
    }

    &:not(.breeze-3__item-active):hover{
      cursor: pointer;
      width: 40%;
      @media (max-width: $md) {
        width: 100%;
      }

      & .breeze-3__item_img{
        transform: translate(-50%, -50%) scale(1.05);
      }
    }

    &_title{
      margin-bottom: .2em;
      transform: translateY(-50%);
      opacity: 0;
      font-size: 3.555em;
      font-weight: 700;
      line-height: 1.2;
      color: #fff;
      text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.58);
      transition: .2s all ease .2s;

      @media (max-width: $sm) {
        font-size: 2.6em;
      }
    }

    &_subtitle{
      position: absolute;
      bottom: 2.5em;
      left: 0;
      display: inline-block;
      padding: .5em 2.2em;
      background: #fff;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
      font-size: 1.66em;
      font-weight: 600;
      margin-bottom: 1em;

      @media (max-width: $lg) {
        font-size: 1.5em;
        padding: .5em 1.3em;
      }

      @media (max-width: $sm) {
        padding: .2em 1em;
      }
    }

    &_list{
      opacity: 0;
      transition: .2s opacity ease;

      &_item{
        position: relative;
        padding-left: 1.5em;
        font-size: 1.33em;
        font-weight: 600;
        line-height: 1.25;
        margin-bottom: 1em;
        color: #fff;

        &::before{
          content: '+';
          position: absolute;
          left: 0;
          top: .2em;
        }
      }
    }

    &_content{
      position: relative;
      z-index: 5;
    }

    &_img{
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      min-height: 100%;
      min-width: 100%;
      object-fit: cover;
      transition: .2s transform ease;
    }
  }
}

.breeze-4{
  position: relative;
  padding: 10em 0 5em;

  @media (max-width: $md) {
    background: linear-gradient(180deg, #FF3434 0%, #FF6464 89.67%);
  }

  &::before{
    content: '';
    position: absolute;
    z-index: 5;
    top: 3em;
    left: 50%;
    transform: translateX(-50%);
    width: 4.667em;
    height: 4.667em;
    background: url(~/images/section__plus-white.svg) no-repeat center center;
    background-size: contain;
  }

  &__bg{
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 74%;
    display: block;
    background: url(~/images/breeze-4__bg.png) no-repeat top center;
    background-size: cover;

    @media (max-width: $md) {
      display: none;
    }
  }

  & .container{
    position: relative;
    z-index: 5;
  }

  &__title{
    font-weight: bold;
    font-size: 3.555em;
    line-height: 1.12;
    text-align: center;
    color: #fff;

    @media (max-width: $md) {
      font-size: 2.33em;
    }
  }

  &__subtitle{
    margin-top: .5em;
    text-align: center;
    color: #fff;
    font-weight: 300;
    font-size: 2em;
    line-height: 1.33;

    @media (max-width: $sm) {
      font-size: 1.66em;
    }
  }

  &__img{
    position: relative;
    z-index: 1;
    margin: 0 auto;
    width: 52%;

    @media (max-width: $md) {
      width: 100%;
      max-width: 600px;
    }
  }

  &__cherry{
    position: absolute;
    z-index: 5;
    left: 2%;
    width: 80%;
    bottom: 13%;

    @media screen {
      left: 0%;
      width: 100%;
      bottom: inherit;
      top: 24em;
    }
  }

  &__items{
    position: relative;
    z-index: 10;
    margin-top: 1em;
  }

  &__item{
    position: absolute;
    z-index: 10;
    max-width: 400px;
    margin: 1em auto;
    font-weight: 600;
    font-size: 1.33em;
    line-height: 1.375;
    color: #fff;
    transition: .4s all ease .05s;

    @media (max-width: $md) {
      position: relative;
      padding-left: 50px;

      &::before{
        content: "+";
        position: absolute;
        left: 0;
        top: 2px;
        width: 30px;
        height: 30px;    
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border-radius: 100%;
        background: #fff;
        font-size: 1.33em;
        color: #ff3737;
      }
    }

    &-hide{
      opacity: 0;
      transform: translateY(-100%);
    }

    &-1{
      left: 23%;
      top: 1.5em;
      width: 15em;
      @media (max-width: $md) {
        left: inherit;
        top: inherit;
        width: 100%;
      }
    }
    &-2{
      right: 8%;
      top: 2em;
      width: 15em;
      @media (max-width: $md) {
        right: inherit;
        top: inherit;
        width: 100%;
      }
    }
    &-3{
      left: 11%;
      top: 42%;
      width: 12em;
      @media (max-width: $md) {
        left: inherit;
        top: inherit;
        width: 100%;
      }
    }
    &-4{
      right: 10%;
      top: 33%;
      width: 9em;
      @media (max-width: $md) {
        right: inherit;
        top: inherit;
        width: 100%;
      }
    }
    &-5{
      left: 19%;
      top: 71%;
      width: 7em;
      @media (max-width: $md) {
        left: inherit;
        top: inherit;
        width: 100%;
      }
    }
    &-6{
      right: 9%;
      top: 88%;
      width: 12em;
      @media (max-width: $md) {
        right: inherit;
        top: inherit;
        width: 100%;
      }
    }
    &-5,
    &-6{
      font-weight: 300;
      color: #000;
      
      @media (max-width: $md) {
        color: #fff;
        font-weight: 600;
      }
    }

    &-circle{
      left: 26%;
      top: 45%;
      border-radius: 100%;
      width: 7.5em;
      height: 7em;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      background: linear-gradient(180deg, #DD1F2B 0%, #FF3737 100%);
      border-radius: 100%;
      
      @media (max-width: $md) {
        position: absolute;
        left: 2%;
        top: 8em;
        padding-left: 0;
        
        &::before{
          display: none;
        }
      }

    }
  }
}

@keyframes breezeCircleArrows1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes breezeCircleArrows2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.breeze-5{
  position: relative;
  padding: 10em 0 5em;

  &::before{
    content: '';
    position: absolute;
    top: 3em;
    left: 50%;
    transform: translateX(-50%);
    width: 4.667em;
    height: 4.667em;
    background: url(~/images/section__plus-dark.svg) no-repeat center center;
    background-size: contain;
  }

  &__title{
    font-weight: bold;
    font-size: 3.555em;
    line-height: 1.375;
    text-align: center;

    @media (max-width: $md) {
      font-size: 2.33em;
    }
  }

  &__schema{
    position: relative;
    margin: 3em auto 0;
    width: 70%;

    @media (max-width: $lg) {
      width: 100%;
    }
    @media (max-width: $md) {
      font-size: 12px;
    }
    
    &_body{
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: $sm) {
        justify-content: flex-start;
        padding-left: 5em;
        padding-top: 2em;
        padding-right: 5em;
      }
    }

    &_footer{
      margin: 3em auto 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      @media (max-width: $sm) {
        padding-bottom: 5em;
        width: 64em;
      }
    }
  }

  &__item{
    position: relative;
    margin: 5em 3em 0;
  }

  &__note{
    position: absolute;
    display: inline-block;
    padding: 1em 2em;
    background: #fff;
    background: #FFFFFF;
    box-shadow: .5em .5em 2em rgba(0, 0, 0, 0.25);
    border-radius: 1.66em;
    font-size: 1.33em;
    font-weight: 600;
    line-height: 1.25;
    text-align: center;

    &::before{
      content: '';
      position: absolute;
      left: 2em;
      top: 100%;
      display: block;
      border: .7em solid transparent;
      border-top: 1em solid #fff;
    }

    &::after{
      content: '';
      position: absolute;
      top: calc(100% + 2em);
      left: 2em;
      width: 3em;
      height: 4em;
      background: url(~/images/breeze-5__arrow-grey.svg) no-repeat center center;
      background-size: contain;
    }

    &-1{
      top: -4em;
      left: -5em;

      &::after{

      }
    }

    &-2{
      top: -3em;
      left: -4.5em;
    }
    
    &-3{
      top: -3em;
      right: -6.5em;
      &::before{
        left: inherit;
        right: 2em;
      }

      &::after{
        transform: rotate(-108deg);
        left: inherit;
        right: 2em;
      }
    }

    &-4{
      &::after{
        left: 1em;
        transform: rotate(-15deg);
        background: url(~/images/breeze-5__arrow-red-1.svg) no-repeat center center;
        background-size: contain;
      }
    }
    &-5{
      &::before{
        left: inherit;
        right: 2em;
      }
      &::after{
        left: inherit;
        right: 2em;
        background: url(~/images/breeze-5__arrow-red-2.svg) no-repeat center center;
        background-size: contain;
      }
    }

    &-footer{
      position: relative;
      
      &::before{
        top: inherit;
        bottom: 100%;
        border: .5em solid transparent;
        border-bottom: .7em solid #fff;
      }

      &::after{
        content: '';
        position: absolute;
        bottom: calc(100% + 2em);
        top: inherit;
      }
    }

    &::after{
      content:'';
      position: absolute;
    }
  }

  &__circle{
    background-size: contain;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24em;
    height: 24em;

    &-1{
      &::before{
        transform: rotate(-45deg);
      }
      &::after{
        background: url(~/images/breeze-5__arrows-1.svg) no-repeat center center;
        background-size: contain;
        animation: breezeCircleArrows1 4s linear infinite;
      }
      & .breeze-5__circle_note{
        right: 80%;
      }
    }
    &-2{
      &::after{
        background: url(~/images/breeze-5__arrows-2.svg) no-repeat center center;
        background-size: contain;
        animation: breezeCircleArrows2 4s linear infinite;
      }
      & .breeze-5__circle_note{
        left: 80%;
      }
    }

    &::before{
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: url(~/images/breeze-5__union.png) no-repeat center center;
      background-size: contain;
    }

    &::after{
      content: '';
      position: absolute;
      left: -.5em;
      top: -.5em;
      width: calc(100% + 1em);
      height: calc(100% + 1em);
    }

    &_title{
      position: relative;
      z-index: 5;
      font-weight: 600;
      font-size: 2em;
      line-height: 1.33;
      text-align: center;
      color: #c4c4c4;
    }

    &_note{
      position: absolute;
      z-index: 5;
      top: 50%;
      transform: translateY(-50%);
      background: #ff6464;
      box-shadow: .5em .5em 2em rgba(0, 0, 0, 0.25);
      border-radius: 1.66em;
      padding: 1em 2em;
      font-weight: 600;
      font-size: 1.33em;
      line-height: 33px;
      color: #fff;
    }
  }

  &__result{
    position: relative;
    display: flex;
    padding: 1em 2em;
    margin: 0 2em;
    background: linear-gradient(180deg, #ff6464 0%, #dd1f2b 100%);
    box-shadow: .5em .5em 2em rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    color: #fff;
    font-weight: 600;
    font-size: 1.33em;
    line-height: 1.375;
    text-align: center;

    &::before,
    &::after{
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 1.5em;
      height: 1em;
    }

    &::before{
      right: calc(100% + 5px);
      background: url(~/images/breeze-5__arrow-red-3.svg) no-repeat center center;
      background-size: contain;
    }
    &::after{
      left: calc(100% + 5px);
      background: url(~/images/breeze-5__arrow-red-4.svg) no-repeat center center;
      background-size: contain;
    }
  }
}

.breeze-6{
  position: relative;
  background: #e5e5e5;
  padding: 10em 0 5em;

  &::before{
    content: '';
    position: absolute;
    top: 3em;
    left: 50%;
    transform: translateX(-50%);
    width: 4.667em;
    height: 4.667em;
    background: url(~/images/section__plus-dark.svg) no-repeat center center;
    background-size: contain;
  }

  &__title{
    font-size: 3.555em;
    line-height: 1.375;
    text-align: center;
    
    @media (max-width: $md) {
      font-size: 2.33em;
    }
  }

  &__items{
    margin-top: 4em;
    position: relative;
    
    @media (min-width: $sm) {
      & .swiper-wrapper{
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
      }
      
      & .swiper-button-next,
      & .swiper-button-prev{
        display: none;
      }
    }
    
    @media (max-width: $sm) {
      padding-top: 3.5em;
      overflow: hidden;
    }
  }

  &__item{
    position: relative;
    background: #fff;
    width: calc(33% - 5em);
    margin: 2.5em;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.4);
    border-radius: 1.66em;
    padding: 3em 1.33em 1.5em 2.5em;

    @media (max-width: $md) {
      font-size: 12px;
      width: calc(33% - 2em);
      margin: 2em 1em;
    }
    @media (max-width: $sm) {
      font-size: 12px;
      width: 100%;
      margin: 2em 0 0;
      box-shadow: none;
    }

    &::before{
      content: '+';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      border-radius: 100%;
      background: #ff6464;
      text-align: center;
      line-height: 1;
      color: #fff;
      font-size: 1.5em;
    }
    
    &_title{
      font-weight: bold;
      font-size: 1.66em;
      line-height: 1.2;
    }
    &_subtitle{
      font-weight: 300;
      font-style: italic;
      font-size: 1.33em;
      line-height: 1.5;
    }

    &_text{
      margin-top: 1em;
      font-size: 1.33em;
      line-height: 1.375;
    }
  }

  &__footer{
    width: 80%;
    margin: 3em auto;

    @media (max-width: $lg) {
      width: 90%;
    }
    @media (max-width: $md) {
      width: 100%;
    }

    &_note{
      font-weight: 300;
      line-height: 1.66;
    }

    &_notaddictive{
      margin-top: 1em;
      text-align: center;
      font-size: 2em;
      font-weight: 300;
      line-height: .83;
    }

    &_danger{
      margin-top: 1em;
      text-align: center;
      font-size: 1.33em;
      font-weight: 600;
      line-height: 1.25;
    }
  }
}

.section-7{
  position: relative;
  background: #f0f0f0;
  overflow: hidden;
  
  &::before{
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    width: 50%;
    height: 100%;
    background: linear-gradient(180deg, #FF3737 0%, #FF6464 100%);

    @media (max-width: $md) {
      display: none;
    }
  }

  & .container{
    @media (max-width: $md) {
      max-width: 100%;
      width: 100%;
      padding: 0;
    }
  }

  &__title{
    padding: 1em 0;
    font-size: 2.66em;
    font-weight: bold;
    line-height: 1;

    @media (max-width: $md) {
      margin-left: 20px;
    }

    @media (max-width: $sm) {
      text-align: center;
    }
  }

  &__items{
    margin-top: 2em;
    display: flex;
    flex-direction: column;
    width: calc(100% - 3em);

    @media (max-width: $md) {
      width: 70%;
      margin: 2em auto 0;
    }
    @media (max-width: $sm) {
      width: 100%;
    }
  }

  &__item{
    border-bottom: 1px solid #c4c4c4;
    
    &_title{
      position: relative;
      font-weight: 300;
      font-size: 1.33em;
      padding: .5em 3em .5em 0;
      cursor: pointer;

      &::after{
        content: '';
        position: absolute;
        background: url(~/images/section-7__arrow.svg) no-repeat center center;
        background-size: contain;
        right: 1em;
        top: .5em;
        width: 1.5em;
        height: 1.5em;
        z-index: 5;
        transition: .2s transform ease;
      }
    }

    &-active{
      & .section-7__item_title{
        font-weight: 400;

        &::after{
          transform: rotate(180deg);
        }
      }
      & .section-7__item_content{
        display: block;
      }
    }

    &_content{
      display: none;
      padding: .66em 0 1em;

      & p{
        line-height: 1.362;
        margin-bottom: 1.5em;
      }
    }
  }

  &__row{
    display: flex;
    flex-direction: row;
    position: relative;
    @media (max-width: $md) {
      flex-direction: column;
    }
  }

  &__col{
    width: 50%;
    padding: 3em 0;

    @media (max-width: $md) {
      width: 100%;
    }

    &-leftside{
      @media (max-width: $md) {
        padding: 40px 20px;
      }
    }

    &-rightside{
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;

      &--align-center{
        align-items: center;
      }

      &--red{
        background: linear-gradient(180deg, #FF3737 0%, #FF6464 100%);
      }

      @media (max-width: $lg) {
        padding-left: 2.5em;
      }
      
      @media (max-width: $md) {
        background: linear-gradient(180deg, #FF3737 0%, #FF6464 100%);
        padding-left: 0;
        align-items: center;
      }

      @media (max-width: $sm) {
        padding: 40px 20px;
      }

      &::after{
        content: "";
        position: absolute;
        background: url(~/images/section-7__question.svg) no-repeat 50%;
        background-size: contain;
        left: 90%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 50%;
        height: 100%;
        z-index: 5;
      }
    }
  }

  &__form{

    @media (max-width: $md) {
      width: 70%;
    }

    @media (max-width: $sm) {
      width: 100%;
    }

    &-success{
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      text-align: center;

      & .section-7__form_error,
      & .section-7__form_field,
      & .section-7__button{
        display: none;
      }

      & .section-7__form_title{
        margin-bottom: .33em;
      }
    }

    &_title{
      margin-bottom: 1.33em;
      font-size: 2.933em;
      font-weight: 700;
      color: #fff;

      @media (max-width: $sm) {
        font-size: 2.33em;
      }
    }
    
    &_subtitle{
      font-size: 1.467em;
      font-weight: 300;
      color: #fff;
    }

    &_textarea,
    &_input{
      display: block;
      width: 100%;
      background: #fff;
      border: 1px solid #c4c4c4;
      padding: 1em;
      outline: none;
      font-size: 1.33em;
      font-weight: 300;
    }
    
    &_field{
      position: relative;
      z-index: 10;
      margin-bottom: 1.9em;

      &::before{
        content: '';
        position: absolute;
        z-index: 5;
        right: 1em;
        top: 1.2em;
        display: block;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        background: #ff6262 url(~/images/close-white.svg) no-repeat center center;
        background-size: 16px;
        opacity: 0;
        transition: .2s opacity ease;
      }

      &-error{
        position: relative;
        
        &::before{
          opacity: 1;
        }
      }

    }

    &_error{
      margin-top: 1.5em;
      opacity: 0;
      color: #fff;
      font-size: 1.2em;
      text-align: center;
      line-height: 1.362;
      transition: .2s opacity ease;

      &-active{
        opacity: 1;
      }
    }
  }

  &__button{
    font-size: 1.33em;
    padding: 1.25em 2.1em;
    max-width: 12em;
    margin: 0 auto;
  }
}

.section-8{
  position: relative;
  background: #f2eae7;
  padding: 9em 0 6em;

  &::before{
    content: '';
    position: absolute;
    top: 3em;
    left: 50%;
    transform: translateX(-50%);
    width: 4.667em;
    height: 4.667em;
    background: url(~/images/section__plus-dark.svg) no-repeat center center;
    background-size: contain;
  }

  &__title{
    font-size: 2.666em;
    font-weight: bold;
    text-align: center;
    line-height: 1.362;

    @media (max-width: $sm) {
      font-size: 2em;
    }
  }
  
  &__subtitle{
    text-align: center;
    font-size: 1.33em;
    font-weight: 300;
    margin-top: .33em;
  }

  &__swiper{
    position: relative;
    margin-top: 4em;
    width: 80%;
    margin: 4em auto;

    @media (max-width: $md) {
      width: 100%;
    }
  }
  &__items{
    width: 90%;
    margin: 0 auto;
    padding-bottom: 3em;

    @media (max-width: $md) {
      width: 100%;
    }

    @media (max-width: $sm) {
      background: none;
      padding-top: 3em;
    }
  }

  &__item{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 3.5em 3em 5em;
    background: #fff;
    font-size: 1.33em;
    line-height: 1.362;
    transition: .2s opacity ease;
    border-radius: 1.66em;
    box-shadow: .6em .6em 1em rgba(0, 0, 0, 0.25);
    @media (max-width: $sm) {
      box-shadow: none;
    }

    &_title{
      font-weight: 600;
    }
    
    &_text{
      font-weight: 300;
    }

    &_date{
      font-weight: 300;
      color: rgba(0, 0, 0, .4);
    }

    &_link{
      position: absolute;
      right: 1.5em;
      bottom: 1.25em;
      display: block;
      text-decoration: none;
      outline: none;
    }
    &_logo{
      height: 1.6em;
    }
  }

  
  & .swiper-container-autoheight, & .swiper-container-autoheight .swiper-slide{
    height: 100%;
  }
}

.section-9{
  position: relative;
  background: #fff;
  padding: 10em 0 6em;

  &::before{
    content: '';
    position: absolute;
    top: 3em;
    left: 50%;
    transform: translateX(-50%);
    width: 4.667em;
    height: 4.667em;
    background: url(~/images/section__plus-dark.svg) no-repeat center center;
    background-size: contain;
  }

  &__title{
    font-size: 2.666em;
    font-weight: bold;
    text-align: center;
    line-height: 1.362;

    @media (max-width: $sm) {
      font-size: 2em;
    }
  }
  
  &__subtitle{
    margin-top: 1.33em;
    text-align: center;
    font-size: 1.33em;
    font-weight: 300;
  }

  &__items{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-top: 5em;

    @media (max-width: $sm) {
      flex-direction: column;
    }
  }

  &__item{
    display: block;
    text-decoration: none;
    outline: none;

    @media (max-width: $sm) {
      max-width: 300px;
      width: 100%;
      margin: 0 auto 2em;
      border-bottom: 1px solid #ccc;
      padding-bottom: 1.5em;

      &:last-child{
        border-bottom: none;
      }
    }
  }
}

.buy-fixed{
  position: fixed;
  right: 0;
  bottom: 10px;
  z-index: 999;
  width: 200px;
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  transform: translateX(120px);
  transition: transform .5s ease;
  border-radius: 40px 0 0 40px;
  background: linear-gradient(89.92deg,#ff3737 .06%,#fcc501 103.67%);
  text-decoration: none;

  &--active,
  &:hover{
    transform: translateX(0);
  }

  &__icon{
    position: relative;
    display: block;
    background: #fff url(~/images/cart.svg) no-repeat center center;
    background-size: 60%;
    width: 60px;
    height: 60px;
    border-radius: 100%;
  }

  &__text{
    margin-left: 20px;
    font-size: 1.2em;
    font-weight: 300;
    line-height: 1.362;
    color: #fff;
  }
}

.simplebar-track.simplebar-horizontal {
  left: 0;
  width: 100%;
  height: 5px;
  background: #e6d7cf;

  & .simplebar-scrollbar {
    top: 0;
    width: 30%;
    height: 5px;
    background: #ff3737;

    &::before{
      display: none;
    }
  }
}

.swiper{
  &__pagination{
    left: 50%!important;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    width: auto!important;

    @media (max-width: $lg) {
      margin-top: 15px;
    }
  }

  &-button-next,
  &-button-prev{
    position: absolute;
    z-index: 100;
    top: 50%;
    width: 2.7em;
    height: 2.7em;
    transform: translateY(-50%);
    border-radius: 100%;
    opacity: .7;
    transition: .2s opacity ease;

    &:hover{
      opacity: 1;
    }

    @media (max-width: $sm) {
      top: 24px;
      transform: translateY(0);
      border: 2px solid #ff3737;
    }
  }

  &-button-prev{
    left: 0;
    background: url(~/images/slider-arrow-left.svg) no-repeat 45% center;
    background-size: 50%;
    
    @media (max-width: $sm) {
      left: inherit;
      right: 3em;
      background-size: 40%;
    }
  }
  &-button-next{
    right: 0;
    background: url(~/images/slider-arrow-right.svg) no-repeat 55% center;
    background-size: 50%;

    @media (max-width: $sm) {
      background-size: 40%;
    }
  }
}

.footer{
  padding: 2em;
  background: #f0f0f0;
  
  @media (max-width: $sm) {
    padding: 1em .5em;
  }

  &__danger{
    width: 100%;
  }
}